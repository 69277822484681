import * as React from "react"
import "../styles/login.scss";
import "../styles/responsive.scss";
import { StaticImage } from "gatsby-plugin-image";
import { useState } from "react";
import fibserSvg from '../images/svg/fibser-svg.svg';
import inputError from '../images/svg/error.svg';
import showEye from '../images/svg/show-eye.svg';
import hideEye from '../images/svg/hide-eye.svg';
import errorPassword from '../images/svg/error-password.svg';
import correctPassword from '../images/svg/correct-password.svg';
import purpleArrow from '../images/svg/right-arrow.svg';
import whiteArrow from '../images/svg/white-right-arrow.svg';
import gmail from '../images/svg/google-coloured.svg';
import gmailGrey from '../images/svg/google-grey.svg';
import linkedIn from '../images/svg/linkedIn-coloured.svg';
import linkedInGrey from '../images/svg/linkedIn-grey.svg';
import mail from '../images/svg/mail-coloured.svg';
import mailGrey from '../images/svg/mail-grey.svg';

const LoginPage = () => {
    const [isOpened, setIsOpened] = useState(false);

  function toggle() {
    setIsOpened(wasOpened => !wasOpened);
  }
    return(
       <main className="login-page">
           <div className="container">
                    <div className="row">
                        <div className="col-12">
                        <div className="main-wrapper">
                            <div className="login-page-img for-web">
                                 <StaticImage draggable={false} className="img-fluid" src={'../images/login-main.png'} placeholder="blurred" alt='logo' />
                            </div>
                            <div className="login-page-img for-mobile">
                                 <StaticImage draggable={false} className="img-fluid" src={'../images/login-main-mob.png'} placeholder="blurred" alt='logo' />
                            </div>
                            {/* show conditionally below "right-panel" div, for showing sign in methods via gmail, linkedIn etc */}
                            {!isOpened &&
                            <div className="right-panel sign-in-methods">
                                 <img src={fibserSvg} />
                                 <div className="main-heading">Sign in to your Account</div>
                                 <div className="sub-text">Sign In to your account and start building your App today</div>
                                 <div className="cards-wrapper">
                                     {/* apply click function for gmail login on below card */}
                                     <div className="sign-in-card">
                                        <img src={gmail} />
                                        <img src={gmailGrey} />
                                     </div>
                                     {/* apply click function for linkedIn login on below card */}
                                     <div className="sign-in-card">
                                        <img src={linkedIn} />
                                        <img src={linkedInGrey} />
                                     </div>
                                     {/* apply click function to open below "right-panel sign-in-form" div (sign in form) on below card */}
                                     <div className="sign-in-card" onClick={toggle}>
                                        <img src={mail} />
                                        <img src={mailGrey} />
                                     </div>
                                 </div>
                                 <div className="sub-text">Create your account for free and start building your App today</div>
                                 {/* apply click function for create your account on below "create-account-btn" div */}
                                 <div className="create-account-btn">
                                     <span>Create your Account</span>
                                     <img src={purpleArrow} />
                                 </div>
                            </div>}

                            {/* show conditionally below "right-panel" div, for showing sign in form */}
                            {isOpened &&
                            <div className="right-panel sign-in-form">
                                <img src={fibserSvg} className="fibser-logo" />
                                {/* apply click function for back button on below "back-btn" div */}
                                <div className="back-btn" onClick={toggle}>
                                  <StaticImage draggable={false} width={24} height={24} src={'../images/back-arrow.png'} placeholder="blurred" alt='logo' />
                                  <span>Back</span>   
                                </div>
                                 <div className="main-heading">Sign in to your Account</div>
                                 <div className="sub-text">Sign In to your account and start building your App today</div>
                                 <div className="signIn-form">
                                     {/* show below "error-banner" div, for showing error-banner */}
                                     <div className="error-banner">
                                            <div className="error-icon">
                                               <img src={inputError} />
                                            </div>
                                            <span>Please re-check your combination of Email and password</span>
                                     </div>

                                     {/* conditionally apply "is-error" class on below "form-group" div, for showing red border of input box */}
                                     <div className="form-group is-error">
                                         <label className="form-label">EMAIL</label>
                                         <input className="form-input" type={'text'} placeholder="Enter Email ID" />
                                         {/* show below "form-inpu-hint" span, only when error is to show, and at same condition "is-error" class is applied on above "form-group" div */}
                                         <span className="form-input-hint">
                                            <img src={inputError} />
                                            <span>Please enter valid email ID</span>
                                         </span>
                                     </div>
                                     <div className="form-group">
                                         <label className="form-label">PASSWORD</label>
                                         <div className="input-icon">
                                           <input className="form-input for-password" type={'password'} placeholder="Enter Password" />
                                           <div className="eye-icons">
                                             {/* show below StaticImage, when password is hidden */}
                                             <img src={showEye} />

                                             {/* show below StaticImage, when password is shown */}
                                             <img src={hideEye} />
                                           </div>
                                         </div>
                                     </div>
                                     <div className="password-instructions">
                                         <div className="password-instruction">
                                             {/* show below "info-password-new.png" StaticImage, when password instruction is incorrect */}
                                             <img src={errorPassword} />
                                             <span>atleast one capital letter</span>
                                         </div>
                                         <div className="password-instruction">
                                             {/* show below "correct-password-new.png" StaticImage, when password instruction is correct */}
                                             <img src={correctPassword} />
                                             <span>one special character</span>
                                         </div>
                                         <div className="password-instruction">
                                             {/* show below "correct-password-new.png" StaticImage, when password instruction is correct */}
                                             <img src={correctPassword} />
                                             <span>atleast 8 character</span>
                                         </div>
                                         <div className="password-instruction">
                                             {/* show below "info-password-new.png" StaticImage, when password instruction is incorrect */}
                                             <img src={errorPassword} />
                                             <span>atleast 1 number</span>
                                         </div>
                                     </div>
                                 </div>
                                 {/* apply click function for sign in on below button */}
                                 <button className="sign-in-btn">
                                     <span>SIGN IN</span>
                                     <img src={whiteArrow} />
                                 </button>

                                 {/* conditionally show below, as per design for create new account */}
                                 <div>
                                   <div className="sub-text">Create your account for free and start building your App today</div>
                                   {/* apply click function for "create your account" on below "acreate-account-btn" div */}
                                   <div className="create-account-btn">
                                       <span>Create your Account</span>
                                       <img src={purpleArrow} />
                                   </div>
                                </div>
                            </div>}
                            </div>
                        </div>
                    </div>
                </div>
       </main>
    )
}

export default LoginPage